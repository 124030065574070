



























import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import Layout from '@/app.user/views/Layout.vue';
import LoginLayout from '@/views/login/LoginLayout.vue';
import { EventBus } from './eventBus';
import ApiService from '@/utility/ApiService';
import GlobalDialogs from '@/app.user/components/dialogs/GlobalDialogs.vue';
import { Toast } from '@/components';

import { AuthStore, StateStore } from '@/store/modules';
import { getModule } from 'vuex-module-decorators';
import { ErrorResponse } from '@/models/responses';

const authStore = getModule(AuthStore);
const stateStore = getModule(StateStore);

@Component({
  components: {
    Layout,
    LoginLayout,
    GlobalDialogs,
    Toast
  }
})
export default class App extends Vue {
  private initLoading = true;

  private timeout = 8000;
  private snackbar = false;

  get successToastTimeout() {
    return stateStore.successToastTimeout;
  }

  get successToast() {
    return stateStore.successToast;
  }

  set successToast(value: boolean) {
    stateStore.ToggleSuccessToast(value);
  }

  get successToastMessage() {
    return stateStore.successToastMessage;
  }

  // Can show layout if we are not on the login page
  get canShowLayout() {
    return !this.$route.path.includes('/login');
  }

  get component() {
    if (this.canShowLayout) {
      return 'layout';
    }
    return 'login-layout';
  }

  get fatalErrorState() {
    return this.$store.getters.hasFatalError;
  }

  get fatalError() {
    return this.$store.getters.fatalError;
  }

  get errors() {
    return this.$store.getters.errors as ErrorResponse[];
  }

  get errorCount() {
    return this.$store.getters.errors.length;
  }

  get snackBarText() {
    if (this.errorCount === 0) {
      return '';
    }
    return this.errors[0].message;
  }

  @Watch('errors')
  private onErrorsChanged(newError: ErrorResponse[], oldError: ErrorResponse[]) {
    if (newError.length > 0) {
      this.snackbar = true;
    }
  }

  private async created() {
    ApiService.SetAuthStore(authStore); // Hack to get the auth store to teh service without a getModule() circular reference
    await authStore.CheckSession();

    this.$nextTick(() => {
      this.initLoading = false;
    });
  }
}

