




















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { EventBus } from '@/app.user/eventBus';
import { DocumentCard, DocumentDialog } from '@/app.user/components';


@Component({
    components: {
        DocumentCard,
        DocumentDialog
    }
})
export default class SearchResults extends Vue {

    private itemsPerPage = 8;
    private page = 1;
    private search = '';

    private currentTab: number = 0;
    private dialog = false;

    @Watch('currentTab')
    private onCurrentTabChange() {
        this.page = 1;
    }

    @Watch('itemsPerPage')
    private onItemsPerPageChange(value: number) {
        if (this.page > this.numberOfPages) {
            this.page = this.numberOfPages;
        }
    }


    get currentyShowingFloor() {
        return Math.max(this.page - 1, 0) * this.itemsPerPage;
    }

    get currentyShowingCeiling() {
        return Math.min(this.page * this.itemsPerPage, this.itemsLength);
    }

    get itemsLength() {
        return this.tabsCards[this.tabs[this.currentTab]].length;
    }

    get numberOfPages() {
        return Math.ceil(this.itemsLength / this.itemsPerPage);
    }

    get dark() {
        return (this as any).$vuetify.theme.dark;
    }

    private cardClicked(type: string) {
        this.dialog = true;
    }

    private formerPage() {
        this.page = Math.max(this.page - 1, 0);
    }

    private nextPage() {
        this.page = Math.min(this.page + 1, this.numberOfPages);
    }

    private updateItemsPerPage(number: number) {
        this.itemsPerPage = number;
    }

    private getRandomCards() {
        const count = Math.floor(Math.random() * 28) + 5;
        const arr = [];
        for (let i = 0; i < count; i++) {
            const doc = Math.random() > 0.5;
            if (doc) {
                arr.push({type: 'document'});
            } else {
                arr.push({type: 'video'});
            }
        }

        return arr;
    }

    private created() {
        this.tabs.forEach((tab) => {
            const cards = this.getRandomCards();
            this.tabsCards[tab] = cards;
        });
    }

    private tabsCards: Record<string, Array<any>> = {};

    private tabs = [
        'All',
        'Explore MBD',
        'Training Modules',
        'CAD Toolkit'
    ];

    private cards = [
        {type: 'document'},
        {type: 'video'},
        {type: 'document'},
    ];

    private itemsPerPageArray = [
        4, 8, 12, 20, 30, 50
    ];
}
