import Vue from 'vue';
import vuetify from './plugins/vuetify';
import '@/app.user/_styles/index.scss';
import '@/styles/index.scss';

// Used for session check after init
import { AuthStore } from '@/store/modules';
import { getModule } from 'vuex-module-decorators';
import store from '@/store/store';

import App from './App.vue';

import Fragment from 'vue-fragment';
import { hasPermDirective, hasContentAccessDirective } from '@/directives/if-perm';
import router from './routing/router';

Vue.use(
  Fragment.Plugin,
);

Vue.config.productionTip = false;

new Vue({
  store,
  vuetify,
  router,
  render: (h) => h(App),
  async beforeCreate() { // Check session as early as possible
    const authStore = getModule(AuthStore);
    await authStore.CheckSession();
  }
}).$mount('#app');

Vue.directive('has-perm', hasPermDirective);
Vue.directive('has-access', hasContentAccessDirective);
