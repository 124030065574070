import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Home from '@/app.user/views/Home.vue';
import ExploreMBD from '@/app.user/views/ExploreMBD.vue';
import Training from '@/app.user/views/Training.vue';
import SearchResultsAlt from '@/app.user/views/SearchResultsAlt.vue';

import LoginTabs from '@/views/login/LoginTabs.vue';
import ForgotPassword from '@/views/login/ForgotPassword.vue';
import ResetPassword from '@/views/login/ResetPassword.vue';
import VerifyAndSetPassword from '@/views/login/VerifyAndSetPassword.vue';

import TermsOfUse from '@/app.user/views/TermsOfUse.vue';
import PrivacyPolicy from '@/app.user/views/PrivacyPolicy.vue';

import _EmptyRouteView from '@/app.user/views/_EmptyRouteView.vue';
import { RouteManager } from '@/_helpers';
import { appRoutes } from '@/app.user/_common/app.routes';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
      path: '/login',
      component:  { render(createElement) {
          return createElement('router-view');
      }},
      children: [
          {
              name: 'Login',
              path: '',
              component: LoginTabs
          },
          {
              name: 'ForgotPassword',
              path: 'forgotPassword',
              component: ForgotPassword
          },
          {
              name: 'ResetPassword',
              path: 'resetPassword',
              component: ResetPassword
          },
          {
            name: 'VerifyAndSetPassword',
            path: 'verifyAndSetup',
            component: VerifyAndSetPassword
          }
      ]
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/alt/search/results',
    name: 'SearchResultsAlt',
    component: SearchResultsAlt
  },
  {
    path: '/terms',
    name: 'TermsOfUse',
    component: TermsOfUse
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/account',
    name: 'AccountSettings',
    component: () => import('@/app.user/views/AccountSettings.vue')
  },
  appRoutes.Explore_CadToolkit,
  appRoutes.Explore_ExploreMBD,
  appRoutes.Explore_Training,
  appRoutes.MBD_Guidelines,
  // appRoutes.Standards,
  appRoutes.ViewCourse,
  appRoutes.Search,
];

const router = new VueRouter({
  routes,
});

router.beforeEach(RouteManager.beforeEach);
router.beforeResolve(RouteManager.beforeResolve);

export default router;
