























































































































import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import LoaderView from '@/views/Loader.vue';
import { EventBus } from '../eventBus';

import { ApiHelpers } from '@/_helpers';
import ApiService from '@/utility/ApiService';
import ApiRoutes from '@/_common/ApiRoutes';
import { BasicUserResponse } from '@/app.admin/models';

import { AuthStore, SearchStore } from '@/store/modules';
import { getModule } from 'vuex-module-decorators';
import { MbdGuidelinesStore } from '@/app.admin/store/modules';
import { PermissionsEnum } from '@/_common/permission.enums';
import {PopUpCard} from '@/app.user/components/LinkTextAndPopUp';

const authStore = getModule(AuthStore);
const searchStore = getModule(SearchStore);
const guidelinesStore = getModule(MbdGuidelinesStore);

@Component({
  components: {
    LoaderView,
    PopUpCard
  }
})
export default class Layout extends Vue {

private mobile = false;
private mobileNav = false;
private windowWidth = window.innerWidth;
private showSearchBarButton = false;

get guidelinesPermissions() {
    if (!authStore.TokenValues) {
      return false;
    }
    return authStore.TokenValues!.permissions.indexOf(PermissionsEnum[PermissionsEnum.MbdGuidelinesAccess]) !== -1;
  }

    get dark() {
        return (this as any).$vuetify.theme.dark;
    }

    get searchTerm() {
      return searchStore.search;
    }

    set searchTerm(value: string) {
      searchStore.SetSearch(value);
    }

    get guidelines() {
        const filteredGuidelines = guidelinesStore.GuidelinesArray.filter((item) => item.contentAccessLevel !== 'Internal');
        return filteredGuidelines.sort((a: any, b: any) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
        });
    }

    private searchTermWithNumbers() {
      // if searchTerm contains a number
      let newSearchTerm = this.searchTerm;
      const termArray = this.searchTerm.split(' ');
      for (let i = 0; i < termArray.length; i++) {
        if (termArray[i] === '1') {
          newSearchTerm += ' one';
        } else if (termArray[i] === '2') {
          newSearchTerm += ' two';
        } else if (termArray[i] === '3') {
          newSearchTerm += ' three';
        } else if (termArray[i] === '4') {
          newSearchTerm += ' four';
        } else if (termArray[i] === '5') {
          newSearchTerm += ' five';
        } else if (termArray[i] === '6') {
          newSearchTerm += ' six';
        } else if (termArray[i] === '7') {
          newSearchTerm += ' seven';
        } else if (termArray[i] === '8') {
          newSearchTerm += ' eight';
        } else if (termArray[i] === '9') {
          newSearchTerm += ' nine';
        }
      }
      for (let i = 0; i < termArray.length; i++) {
        if (termArray[i] === 'one') {
          newSearchTerm += ' 1';
        } else if (termArray[i] === 'two') {
          newSearchTerm += ' 2';
        } else if (termArray[i] === 'three') {
          newSearchTerm += ' 3';
        } else if (termArray[i] === 'four') {
          newSearchTerm += ' 4';
        } else if (termArray[i] === 'five') {
          newSearchTerm += ' 5';
        } else if (termArray[i] === 'six') {
          newSearchTerm += ' 6';
        } else if (termArray[i] === 'seven') {
          newSearchTerm += ' 7';
        } else if (termArray[i] === 'eight') {
          newSearchTerm += ' 8';
        } else if (termArray[i] === 'nine') {
          newSearchTerm += ' 9';
        }
      }
      return newSearchTerm;
    }

    private initializeSearch() {
      if (this.searchTerm) {
        this.$router.push({name: 'SearchResults', query: {query: this.searchTermWithNumbers() }});
      }
    }

    private async logout() {
        await authStore.Logout(this.$router);
    }

    private async triggerAccountSettings() {
      const newDataResult = await ApiHelpers.wrapCallWithResult<BasicUserResponse>(async () =>
          await ApiService.Get(ApiRoutes.Global.Identity.GetUser(authStore.TokenValues!.userId))
      );

      if (newDataResult) {
        this.$router.push({name: 'AccountSettings', params: {
            username: newDataResult.username,
            email: newDataResult.email,
            firstName: newDataResult.firstName,
            lastName: newDataResult.lastName,
            organization: newDataResult.organization,
            notes: newDataResult.notes
        }});
      }
    }

    private termsAndContact = document.getElementsByClassName('termsAndContact');
    private showTerms: boolean = true;

    private closeContact() {
      return this.showTerms = false;
    }

    private checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1150) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    }

    private showSearchBar() {
      if (this.showSearchBarButton === true || this.mobile === false) {
        return true;
      } else {
        return false;
      }
    }

    private toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    }

    private onRouteChange() {
      this.mobileNav = false;
    }

    private toggleSearchBar() {
      this.showSearchBarButton = !this.showSearchBarButton;
    }

    private created() {
      window.addEventListener('resize', this.checkScreen);
      this.checkScreen();
    }

}
