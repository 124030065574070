import Vue from 'vue';
import { TagsStore, PropertiesStore, FilesStore, PackagesStore, MbdGuidelinesStore, StandardsStore, FileSystemStore, CoursesStore } from '@/app.admin/store/modules';
import { getModule } from 'vuex-module-decorators';
import ApiService from '@/utility/ApiService';

import { VimeoVideo, CourseResponse } from '@/models/entities/course';
import { CourseProgressResponse, ModuleProgressResponse, FileProgressResponse } from '@/models/entities/progress';

import ApiRoutes from '@/_common/ApiRoutes';
import { ApiHelpers } from '@/_helpers';

import { SimpleItemResponse } from '@/app.user/models/SimpleItemResponse';
import { BasicUserResponse, SpecialContentAccessResponse } from '@/app.admin/models';
import { MbdGuideline, SimpleEntity } from '@/models';
import AuthStore from '@/store/modules/authStore';

const mbdGuidelinesStore = getModule(MbdGuidelinesStore);
const standardsStore = getModule(StandardsStore);
const authStore = getModule(AuthStore);

// Route data action references
const routeActions: RouteActions = {
    simple: {
        mbdGuidelineCategories: mbdGuidelinesStore.fetchCategories,
        mbdGuidelines: mbdGuidelinesStore.fetchGuidelines,
        standards: standardsStore.fetchStandards,
        standardBodies: standardsStore.fetchStandardBodies,
        cadToolkits: async () => {
            return await ApiHelpers.wrapCallWithResult<SimpleItemResponse[]>(
                async () => ApiService.Get<SimpleItemResponse[]>(ApiRoutes.User.Content.CadToolkits));
        },
        exploreMbe: async () => {
            return await ApiHelpers.wrapCallWithResult<SimpleItemResponse[]>(
                async () => ApiService.Get<SimpleItemResponse[]>(ApiRoutes.User.Content.ExploreMbe));
        },
        training: async () => {
            return await ApiHelpers.wrapCallWithResult<SimpleItemResponse[]>(
                async () => ApiService.Get<SimpleItemResponse[]>(ApiRoutes.User.Content.Training));
        },
        guidelines: async () => {
            return await ApiHelpers.wrapCallWithResult<MbdGuideline[]>(
                async () => ApiService.Get<MbdGuideline[]>(ApiRoutes.User.MbdGuideline.GetAllGuidelines));
        },
        guidelineCategories: async () => {
                return await ApiHelpers.wrapCallWithResult<SimpleEntity[]>(
                    async () => await ApiService.Get<SimpleEntity[]>(ApiRoutes.User.MbdGuideline.GetCategories));
        },
        searchResults: async (query: string, category: string | undefined) => {
            const queryParams: Record<string, string> = { query: query };
            if (category) {
                queryParams.category = category;
            }

            return await ApiHelpers.wrapCallWithResult<SimpleItemResponse[]>(
                async () => ApiService.Get<SimpleItemResponse[]>(ApiRoutes.User.Content.Search, queryParams));
        },
        specialContentNames: async () => {
            return await ApiHelpers.wrapCallWithResult<SpecialContentAccessResponse[]>( async () =>
                await ApiService.Get<SpecialContentAccessResponse[]>(ApiRoutes.User.Content.GetSpecialContentNames)
            );
        },
        allUserProgress: async () => {
            const user = await ApiHelpers.wrapCallWithResult<BasicUserResponse>(async () =>
                    await ApiService.Get(ApiRoutes.Global.Identity.GetUser(authStore.TokenValues!.userId))
                );
            if (user !== undefined) {
                return await ApiHelpers.wrapCallWithResult<CourseProgressResponse[]>( async () =>
                await ApiService.Get<CourseProgressResponse[]>(ApiRoutes.Global.Progress.GetAllUserProgress(user.username))
                );
            }
        },
        allUserFileProgress: async () => {
            const user = await ApiHelpers.wrapCallWithResult<BasicUserResponse>(async () =>
                    await ApiService.Get(ApiRoutes.Global.Identity.GetUser(authStore.TokenValues!.userId))
                );
            if (user !== undefined) {
                return await ApiHelpers.wrapCallWithResult<FileProgressResponse[]>( async () =>
                await ApiService.Get<FileProgressResponse[]>(ApiRoutes.Global.Progress.GetAllUserFileProgress(user.username))
                );
            }
        },
        user: async () => {
            return await ApiHelpers.wrapCallWithResult<BasicUserResponse>( async () =>
                await ApiService.Get<BasicUserResponse>(ApiRoutes.Global.Identity.GetUser(authStore.TokenValues!.userId))
            );
        },
    },
    withOptions: {
    },
    withRouteParams : { // Route data action references that require route parameters to execute
        course: {
            action: async (id) => {
                return await ApiHelpers.wrapCallAndModifyValue<CourseResponse, CourseResponse>( async () =>
                    await ApiService.Get<CourseResponse>(ApiRoutes.User.Course.GetCourse(id)),
                    (value) => {
                        return new Vue({ data: value }); // Make it reactive
                    }
                );
            },
            params: 'courseId'
        },
        courseModule: {
            action: async (courseId, moduleId) => {
                return await ApiHelpers.wrapCallAndModifyValue<VimeoVideo, VimeoVideo>( async () =>
                    await ApiService.Get<VimeoVideo>(ApiRoutes.User.Course.GetCourseModule(courseId, moduleId)),
                    (value) => {
                        return new Vue({ data: value }); // Make it reactive
                    }
                );
            },
            params: ['courseId', 'moduleId']
        },
        guideline: {
            action: async (guidelineId) => {
                return await ApiHelpers.wrapCallAndModifyValue<MbdGuideline, MbdGuideline>( async () =>
                    await ApiService.Get<MbdGuideline>(ApiRoutes.User.MbdGuideline.GetGuideline(guidelineId)),
                    (value) => {
                        return new Vue({data: value});
                    }
                );
            },
            params: 'guidelineId'
        },
        courseProgress: {
            action: async (courseId) => {
                const user = await ApiHelpers.wrapCallWithResult<BasicUserResponse>(async () =>
                    await ApiService.Get(ApiRoutes.Global.Identity.GetUser(authStore.TokenValues!.userId))
                );
                if (user !== undefined) {
                    return await ApiHelpers.wrapCallAndModifyValue<CourseProgressResponse, CourseProgressResponse>( async () =>
                    await ApiService.Get<CourseProgressResponse>(ApiRoutes.Global.Progress.GetCourseProgress(user.username, courseId)),
                        (value) => {
                            return new Vue({data: value});
                        }
                    );
                }
            },
            params: 'courseId'
        },
        moduleProgress: {
            action: async (courseId, moduleId) => {
                const user = await ApiHelpers.wrapCallWithResult<BasicUserResponse>(async () =>
                    await ApiService.Get(ApiRoutes.Global.Identity.GetUser(authStore.TokenValues!.userId))
                );
                if (user !== undefined) {
                    return await ApiHelpers.wrapCallAndModifyValue<ModuleProgressResponse, ModuleProgressResponse>( async () =>
                    await ApiService.Get<ModuleProgressResponse>(ApiRoutes.Global.Progress.GetModuleProgress(user.username, courseId, moduleId)),
                        (value) => {
                            return new Vue({data: value});
                        }
                    );
                }
            },
            params: ['courseId', 'moduleId']
        }
    },
};

 // The interface for selecting route actions from the object defined in routeHelpers
interface RouteActions {
    simple: {
        cadToolkits: () => Promise<SimpleItemResponse[] | undefined>
        exploreMbe: () => Promise<SimpleItemResponse[] | undefined>
        training: () => Promise<SimpleItemResponse[] | undefined>
        guidelines: () => Promise<MbdGuideline[] | undefined>
        guidelineCategories: () => Promise<SimpleEntity[] | undefined>
        searchResults: (query: string, category: string | undefined) => Promise<SimpleItemResponse[] | undefined>
        specialContentNames: () => Promise<SpecialContentAccessResponse[] | undefined>,
        mbdGuidelines: () => Promise<boolean>,
        mbdGuidelineCategories: () => Promise<boolean>,
        standards: () => Promise<boolean>,
        standardBodies: () => Promise<boolean>
        allUserProgress: () => Promise<CourseProgressResponse[] | undefined>,
        allUserFileProgress: () => Promise<FileProgressResponse[] | undefined>,
        user: () => Promise<BasicUserResponse | undefined>
    };

    withOptions: {
    };

    withRouteParams: {
        course: {
            action: (id: string) => Promise<CourseResponse | undefined>,
            params: string
        },
        courseModule: {
            action: (courseId: string, moduleId: string) => Promise<VimeoVideo | undefined>,
            params: string[]
        },
        guideline: {
            action: (guidelineId: string) => Promise<MbdGuideline | undefined>,
            params: string
        },
        courseProgress: {
            action: (courseId: string) => Promise<CourseProgressResponse | undefined>,
            params: string
        },
        moduleProgress: {
            action: (courseId: string, moduleId: string) => Promise<ModuleProgressResponse | undefined>,
            params: string[]
        }
    };
}

export {
    RouteActions,
    routeActions
};
