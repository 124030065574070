







































































































































import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import { FileItemResponse } from '@/app.user/models/ItemResponse';
import DownloadFileButton from '@/app.user/components/DownloadFileButton.vue';
import ApiService from '@/utility/ApiService';
import { ApiHelpers } from '@/_helpers';
import ApiRoutes from '@/_common/ApiRoutes';
import {LinkText} from '@/app.user/components/LinkTextAndPopUp';

@Component({
    components: {
        DownloadFileButton,
        LinkText
    }
})
export default class FileItemDialog extends Vue {
    @PropSync('dialog', {required: true, type: Boolean}) public syncedDialog!: boolean;
    @Prop({required: true, type: String}) public fileId!: string;

    private loading = true;
    private item: FileItemResponse | null = null;

    get hasValue() {
        return !!this.item;
    }

    get hasPropertyValuePairs() {
        if (this.hasValue) {
            return this.item!.propertyValuePairs && this.item!.propertyValuePairs.length > 0;
        }
        return false;
    }

    get id() {
        if (this.hasValue) {
            return this.item!.id;
        }
    }

    get title() {
        if (this.hasValue) {
            return this.item!.title;
        }
    }

    get description() {
        if (this.hasValue) {
            return this.item!.description;
        }
    }

    get type() {
        if (this.hasValue) {
            return this.item!.type;
        }
    }

    get tags() {
        if (this.hasValue) {
            return this.item!.tags;
        }
    }

    get propertyValues() {
        if (this.hasValue) {
            return this.item!.propertyValues;
        }
    }

    get groupedPropertyValues() {
        if (this.hasValue) {
            if (!this.item!.propertyValues) {
                return [];
            }

            const groups: Record<string, string[]> = {};

            this.item!.propertyValues.forEach((prop) => {
                if (!(prop.name === 'Content Access')) {
                    if (!(prop.name in groups)) {
                        groups[prop.name] = [];
                    }
                    groups[prop.name].push(prop.value);
                }
            });

            return groups;
        }
    }

    get groupedPropertyValuePairs() {
        if (this.hasValue) {
            if (!this.item!.propertyValuePairs || this.item!.propertyValuePairs.length === 0) {
                return [];
            }

            // { PrincipleName: { PrincipleValue: [DependantValues]  } }
            const groupedProps: Record<string, Record<string, string[]>>  = {};

            this.item!.propertyValuePairs.forEach((prop) => {
                if (!(prop.principleName in groupedProps)) {
                    groupedProps[prop.principleName] = {};
                }

                if (!(prop.principleValue in groupedProps[prop.principleName])) {
                    groupedProps[prop.principleName][prop.principleValue] = [];
                }

                groupedProps[prop.principleName][prop.principleValue].push(prop.requiredValue);
            });

            return groupedProps;
        }
    }

    get thumbnailUri() {
        if (this.hasValue) {
            return ApiRoutes.Global.Thumbnails.GetUrl(this.item!.thumbnailId!);
        }
    }

    public async created() {
        this.loading = true;

        const result = await ApiHelpers.wrapCallWithResult<FileItemResponse>(async () => ApiService.Get<FileItemResponse>(`/api/v1/content/file/${this.fileId}`));
        if (result) {
            this.item = result;
        }

        this.loading = false;
    }
}
