import { routeActions } from './route.actions';
import { RouteManager } from '@/_helpers';

const appRoutes = {
    Explore_CadToolkit: {
        path: '/explore/cad',
        name: 'CadToolkit',
        props: (route: any) => RouteManager.handlePropsAssignment(route, 'CadToolkit'),
        children: [
            {
                path: '/explore/cad/:tab',
                name: 'CadToolkitTab',
                props: (route: any) => RouteManager.handlePropsAssignment(route, 'CadToolkit'),
                meta: {
                    actions: {
                        simple: [
                            {
                                action: routeActions.simple.cadToolkits,
                                blocking: true,
                                prop: 'items'
                            },
                            {
                                action: routeActions.simple.specialContentNames,
                                blocking: true,
                                prop: 'specialContentNames'
                            },
                            {
                                action: routeActions.simple.allUserProgress,
                                blocking: true,
                                prop: 'allUserProgress'
                            },
                            {
                                action: routeActions.simple.allUserFileProgress,
                                blocking: true,
                                prop: 'allFileProgress'
                            }
                        ]
                    }
                },
                components: {
                    tab: () => import('@/app.user/views/CadToolkit.vue'),
                }
            }
        ],
        meta: {
            actions: {
                simple: [
                    {
                        action: routeActions.simple.cadToolkits,
                        blocking: true,
                        prop: 'items'
                    },
                    {
                        action: routeActions.simple.specialContentNames,
                        blocking: true,
                        prop: 'specialContentNames'
                    },
                    {
                        action: routeActions.simple.allUserProgress,
                        blocking: true,
                        prop: 'allUserProgress'
                    },
                    {
                        action: routeActions.simple.allUserFileProgress,
                        blocking: true,
                        prop: 'allFileProgress'
                    }
                ]
            }
        },
        component: () => import('@/app.user/views/CadToolkit.vue'),
    },
    Explore_ExploreMBD: {
        path: '/explore/mbd',
        name: 'ExploreMBD',
        props: (route: any) => RouteManager.handlePropsAssignment(route, 'ExploreMBD'),
        children: [
            {
                path: '/explore/mbd/:tab',
                name: 'ExploreMBDTab',
                props: (route: any) => RouteManager.handlePropsAssignment(route, 'ExploreMBD'),
                meta: {
                    actions: {
                        simple: [
                        {
                            action: routeActions.simple.exploreMbe,
                            blocking: true,
                            prop: 'items'
                        },
                        {
                            action: routeActions.simple.specialContentNames,
                            blocking: true,
                            prop: 'specialContentNames'
                        },
                        {
                            action: routeActions.simple.allUserProgress,
                            blocking: true,
                            prop: 'allUserProgress'
                        },
                        {
                            action: routeActions.simple.allUserFileProgress,
                            blocking: true,
                            prop: 'allFileProgress'
                        }
                    ]
                    }
                },
                components: {
                    tab: () => import('@/app.user/views/ExploreMBD.vue'),
                }
            }
        ],
        meta: {
            actions: {
                simple: [
                    {
                        action: routeActions.simple.exploreMbe,
                        blocking: true,
                        prop: 'items'
                    },
                    {
                        action: routeActions.simple.specialContentNames,
                        blocking: true,
                        prop: 'specialContentNames'
                    },
                    {
                        action: routeActions.simple.allUserProgress,
                        blocking: true,
                        prop: 'allUserProgress'
                    },
                    {
                        action: routeActions.simple.allUserFileProgress,
                        blocking: true,
                        prop: 'allFileProgress'
                    }
                ]
            }
        },
        component: () => import('@/app.user/views/ExploreMBD.vue'),
    },
    Explore_Training: {
        path: '/explore/training',
        name: 'Training',
        props: (route: any) => RouteManager.handlePropsAssignment(route, 'Training'),
        children: [
            {
                path: '/explore/training/:tab',
                name: 'TrainingTab',
                props: (route: any) => RouteManager.handlePropsAssignment(route, 'Training'),
                meta: {
                    actions: {
                        simple: [
                            {
                                action: routeActions.simple.training,
                                blocking: true,
                                prop: 'items'
                            },
                            {
                                action: routeActions.simple.specialContentNames,
                                blocking: true,
                                prop: 'specialContentNames'
                            },
                            {
                                action: routeActions.simple.allUserProgress,
                                blocking: true,
                                prop: 'allUserProgress'
                            },
                            {
                                action: routeActions.simple.allUserFileProgress,
                                blocking: true,
                                prop: 'allFileProgress'
                            }
                        ]
                    }
                },
                components: {
                    tab: () => import('@/app.user/views/Training.vue'),
                }
            }
        ],
        meta: {
            actions: {
                simple: [
                    {
                        action: routeActions.simple.training,
                        blocking: true,
                        prop: 'items'
                    },
                    {
                        action: routeActions.simple.specialContentNames,
                        blocking: true,
                        prop: 'specialContentNames'
                    },
                    {
                        action: routeActions.simple.allUserProgress,
                        blocking: true,
                        prop: 'allUserProgress'
                    },
                    {
                        action: routeActions.simple.allUserFileProgress,
                        blocking: true,
                        prop: 'allFileProgress'
                    }
                ]
            }
        },
        component: () => import('@/app.user/views/Training.vue'),
    },
    MBD_Guidelines: {
        path: '/mbd-guidelines',
        name: 'MbdGuidelines',
        props: (route: any) => RouteManager.handlePropsAssignment(route, 'MbdGuidelines'),
        children: [
            {
                path: '/mbd-guidelines/:guidelineId',
                name: 'MbdGuidelinesGuideline',
                props: (route: any) => RouteManager.handlePropsAssignment(route, 'MbdGuidelines'),
                meta: {
                    actions: {
                        simple: [
                            {
                                action: routeActions.simple.guidelines,
                                blocking: true,
                                prop: 'items'
                            },
                            {
                                action: routeActions.simple.guidelineCategories,
                                blocking: true,
                                prop: 'categories'
                            },
                            {
                                action: routeActions.simple.specialContentNames,
                                blocking: true,
                                prop: 'specialContentNames'
                            },
                        ],
                    }
                },
                components: {
                    guidelineId: () => import('@/app.user/views/MBDGuidelines.vue'),
                }
            }
        ],
        meta: {
            actions: {
                simple: [
                    {
                        action: routeActions.simple.guidelines,
                        blocking: true,
                        prop: 'items'
                    },
                    {
                        action: routeActions.simple.specialContentNames,
                        blocking: true,
                        prop: 'specialContentNames'
                    },
                    {
                        action: routeActions.simple.guidelineCategories,
                        blocking: true,
                        prop: 'categories'
                    },
                ],
            }
        },
        component: () => import('@/app.user/views/MBDGuidelines.vue'),

    },
    Search: {
        path: '/search',
        name: 'SearchResults',
        props: (route: any) => RouteManager.handlePropsAssignment(route, 'SearchResults'),
        component: () => import('@/app.user/views/SearchResults.vue'),
        meta: {
            isSearch: true,
            actions: {
                withQuery: [
                    {
                        action: routeActions.simple.searchResults,
                        blocking: true,
                        paramKeys: ['query', 'category'],
                        prop: 'items'
                    }
                ],
                simple: [
                    {
                        action: routeActions.simple.specialContentNames,
                        blocking: true,
                        prop: 'specialContentNames'
                    },
                ]
            }
        }
    },
    ViewCourse: {
        path: '/course/:courseId',
        name: 'ViewCourse',
        props: (route: any) => RouteManager.handlePropsAssignment(route, 'ViewCourse'),
        children: [
            {
                path: '/course/:courseId/module/:moduleId',
                name: 'ViewCourseModule',
                props: {
                    video: (route: any) => RouteManager.handlePropsAssignment(route, 'ViewCourseModule'),
                    videoInfo: (route: any) => RouteManager.handlePropsAssignment(route, 'ViewCourseModule'),
                },
                meta: {
                    nav: {
                        drawer: false,
                        label: 'Module',
                    },
                    actions: {
                        withRouteParams: [
                            {
                                ...routeActions.withRouteParams.courseModule,
                                blocking: true,
                                prop: 'model'
                            },
                            {
                                ...routeActions.withRouteParams.courseProgress,
                                blocking: true,
                                prop: 'courseProgress'
                            },
                            {
                                ...routeActions.withRouteParams.moduleProgress,
                                blocking: true,
                                prop: 'moduleProgress'
                            }
                        ],
                        simple: [
                            {
                                action: routeActions.simple.user,
                                blocking: true,
                                prop: 'user'
                            },
                        ]
                    }
                },
                components: {
                    video: () => import('@/app.user/views/courses/CourseModuleVideoView.vue'),
                    videoInfo:  () => import('@/app.user/views/courses/CourseModuleInfoView.vue')
                }
            }
        ],
        meta: {
            nav: {
                drawer: false,
            },
            actions: {
                simple: [
                    {
                        action: routeActions.simple.cadToolkits,
                        blocking: true,
                        prop: 'cadItems'
                    },
                    {
                        action: routeActions.simple.exploreMbe,
                        blocking: true,
                        prop: 'mbeItems'
                    },
                    {
                        action: routeActions.simple.training,
                        blocking: true,
                        prop: 'trainingItems'
                    },
                    {
                        action: routeActions.simple.specialContentNames,
                        blocking: true,
                        prop: 'specialContentNames'
                    },
                    {
                        action: routeActions.simple.user,
                        blocking: true,
                        prop: 'user'
                    }
                ],
                withRouteParams: [
                    {
                        ...routeActions.withRouteParams.course,
                        blocking: true,
                        prop: 'model'
                    },
                    {
                        ...routeActions.withRouteParams.courseProgress,
                        blocking: true,
                        prop: 'courseProgress'
                    }
                ]
            }
        },
        component: () => import('@/app.user/views/courses/CourseView.vue')
    }
};

export {
    appRoutes
};
