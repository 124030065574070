






import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import { EventBus } from '@/app.user/eventBus';
import FileItemDialog from './FileItemDialog.vue';

@Component({
    components: {
        FileItemDialog
    }
})
export default class GlobalDialogs extends Vue {

    public viewFileItemDialog = false;
    public viewFileItemId = '';

    public created() {
        EventBus.$on('view-file-item', (fileId: string) => {
            this.viewFileItemId = fileId;
            this.viewFileItemDialog = true;
        });
    }
}
