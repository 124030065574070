





























































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { EventBus } from '@/app.user/eventBus';
import { IconImage } from '@/app.user/components';
import { getModule } from 'vuex-module-decorators';
import { PermissionsEnum } from '@/_common/permission.enums';
import { AuthStore} from '@/store/modules';
import { SimpleItemResponse } from '@/app.user/models/SimpleItemResponse';
import { ApiHelpers } from '@/_helpers';
import { BasicUserResponse } from '@/app.admin/models';
import ApiService from '@/utility/ApiService';
import ApiRoutes from '@/_common/ApiRoutes';

const authStore = getModule(AuthStore);

@Component({
    components: {
        IconImage
    }
})
export default class Home extends Vue {

    private createProduct() {
        EventBus.$emit('create-product');
    }

    private userPermission: number = -1;

    private async getUser() {
        const user = await ApiHelpers.wrapCallWithResult<BasicUserResponse>( async () =>
            await ApiService.Get<BasicUserResponse>(ApiRoutes.Global.Identity.GetUser(authStore.TokenValues!.userId))
        );

        if (user) {
            this.userPermission = user.specialContentAccess.indexOf('1008');
            return this.userPermission;
        }
    }

    private async goToCourse() {
        if (this.userPermission === -1) {
            window.open('https://www.action-engineering.com/mbd-101-course', '_blank')?.focus();
        } else {
            this.$router.push('/explore/training/mbd-101-course');
        }
    }

    private mounted() {
        this.getUser();
    }

    // private slides = [
    //     {title: 'CAD Toolkit', routeName: 'CadToolkit', image: '/images/HomepageSlider-CADToolkit.png'},
    //     {title: 'Training', routeName: 'Training', image: '/images/HomepageSlider-Training.png'},
    //     {title: 'Explore MBD', routeName: 'ExploreMBD', image: '/images/HomepageSlider-ExploreMBD.png'},
    // ];
}
